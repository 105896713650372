/*
The Google Analytics, Google Tag Manager and HubSpot tracking code should
only run in production so our dev environment testing won't get tracked
and skew the marketing data. We were placing this code in the index.html file 
but that meant the dev and prod versions of the files were different which
caused deployment problems where the settings kept getting overwritten and 
the tracking kept getting turned off by accident. 

There are two main sections of code below. The first part loads the libraries
from Google and HubSpot only if the site is the production site. The second
part creates the data array's used by the libraries and the functions to add
data to the arrays. Since other parts of the code calls those functions and
pushes data onto those arrays we still need them but without the libraries
the data just sits harmlessly in the arrays and goes nowhere.

There is no reference to this script in the index.html file because this file
gets merged into the scripts.js file for better performance. This is controlled
by the angular.json file.
*/
(function() {
  /* Get Body Tag Reference */
  var body = document.getElementsByTagName("body")[0];

  /* Only run this part in production */
  if (location.hostname === 'www.riterouting.com' || location.hostname === 'riterouting.com' || location.port === '3002' || location.port === '3012') {
    /* Google Tag Manager Initialization */
    console.log("Google Tag Manager Initialization");
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5JVJSCM');
    
    /* Create the script tag to prevent Hubspot form scraping */
    /* Note: This is a hack and may not work in the future    */
    console.log("Create the script tag to prevent Hubspot form scraping");
    var script1 = document.createElement("script");
    script1.id = "CollectedForms-4944419";
    script1.innerText = "/* Hack to prevent HubSpot from form scraping */";
    body.prepend(script1);

    /* Start of HubSpot Embed Code */
    console.log("Load the HubSpot Library");
    var script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.id = "hs-script-loader";
    script2.async = "async";
    script2.defer = "defer";
    script2.src = "https://js.hs-scripts.com/4944419.js";
    body.prepend(script2);

    /* Google Analytics GTag */
    // console.log("Load the Google Analytics GTag Library");
    // var script3 = document.createElement("script");
    // script3.async = "async";
    // script3.src = "https://www.googletagmanager.com/gtag/js?id=UA-116238460-1";
    // body.prepend(script3);
  }

  /* The following two scripts run regardless so the vars get set to [] */
  /* Google Analytics GTag */
  // console.log("Create the Google Analytics GTag Code");
  // var script4 = document.createElement("script");
  // script4.id = "GoogleAnalytics";
  // script4.text = `
  //   /* Google Analytics GTag tracking xxx */
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag(){dataLayer.push(arguments);}
  //   // DISABLED
  //   gtag('js', new Date());
  //   gtag('config', 'UA-116238460-1', { cookie_flags: 'SameSite=None;Secure' });
  //   `;
  // body.append(script4);

  /* Load the HubSpot tracking code */
  console.log("Create the HubSpot tracking Code");
  var script5 = document.createElement("script");
  script5.id = "HubspotTracking";
  script5.text = `
    /* HubSpot tracking code */
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', window.location.pathname]);
    // _hsq.push(['identify', user.email]); // Not logged in yet
    _hsq.push(['trackPageView']);`;
  body.append(script5);
})();
